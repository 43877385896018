// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  companyName: "Remax",
  node: "uy",
  apiUrl: "https://api-ar.redremax.com/remaxweb-uy/api/",
  host: "https://www.remax.com.uy",
  googleAPIKey: "AIzaSyD93GCbJh8I6CQRUQDj-16D0Se6ODW62cw",
  googleAPISecret: "hty-wQ_QokHD2IzRKAQnOHnx300=",
  imageBucket: "prod-ar-remax-web-assets",
  imageCloudfront: "https://d1acdg20u0pmxj.cloudfront.net",
  sitemapUrl: "https://prod-ar-remax-web-assets.s3.amazonaws.com",
  robotUrl: "https://prod-ar-remax-web-assets.s3.amazonaws.com/robots-uy.txt",
  imageBucketResizeUrl: "https://d1acdg20u0pmxj.cloudfront.net/",
  testUser: "Xzjn6gXv3g",
  testPass: "9WJeBNLsut",
  cognito: {
    aws_user_pools_id: "us-east-1_c9ck5QjKo",
    aws_user_pools_web_client_id: "5inpctdh7qpqebibo48bsvuuqi",
    domain: "amplifydomain-prodar.auth.us-east-1.amazoncognito.com",
    redirectSignIn: "https://www.remax.com.uy/",
    redirectSignOut: "https://www.remax.com.uy/",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
